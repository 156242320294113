<!--
 * @Author: jjx 1432652396@qq.com
 * @Date: 2022-11-04 14:58:03
 * @LastEditors: jjx 1432652396@qq.com
 * @LastEditTime: 2022-12-05 10:36:09
 * @FilePath: \winshawncrm\src\components\call.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
<div class="layer">
            <div class="outer blockCenter">
                <div class="title flex-between">
                    <span>呼叫</span>
                    <Icon type="ios-close" size="30" @click="closeDialog"/>
                </div>
                <div class="flex-between box" v-if="row.account">
                    <div class="lf flex-between" style="flex-direction:column">
                        <div class="top flex-between">
                            <div class="l">
                                <div class="sub">
                                    <img src="../assets/images/khxx.png" alt=""><span>客户信息</span>
                                </div>
                                <ul>
                                    <li><span>序号</span><span>{{row.userid}}</span></li>
                                    <li><span>用户名</span><span>{{row.account|sliceStr}}</span></li>
                                    <li><span>省份</span><span>{{row.province}}</span></li>
                                    <li><span>科类</span><span>{{row.subjects}}</span></li>
                                    <li><span>分数</span><span>{{row.score}}</span></li>
                                    <li><span>常用功能</span><span>{{row.commonModules}}</span></li>
                                    <li><span>预测交费</span><span>{{row.forecastPay}}</span></li>
                                </ul>    
                            </div>
                            <div class="r flex" style="justify-content:center;flex-direction:column;position: relative;">
                                <div class="flex">
                                    <img src="../assets/images/teltx.png" alt="">
                                    <div class="tel">
                                        <p>{{row.account|sliceStr}}</p>
                                        <p>归属地 {{row.province}}</p>
                                    </div>
                                </div>
                                <!-- <div class="time">通化时长 00:20:05</div> -->
                                <div style="margin:20px auto" v-if="beginCount">{{hour|filterStr}}:{{min|filterStr}}:{{sec|filterStr}}</div>
                                <div style="margin:20px auto" v-if="recordState!=''">通话结束-{{recordState}}</div>
                                <input  style="margin:20px auto" type="button" value="呼叫" class="btn" @click="callItem" v-if="isShow">
                            </div>
                        </div>
                        <div class="bot">
                            <div class="sub">
                                <img src="../assets/images/tjhs.png" alt=""><span>通话小结</span>
                            </div>
                           <slot>
                            <div class="xj" v-if="row.ue ===10204">
                                <div>
                                    <label for="">录取院校:</label>
                                    <textarea v-model="el" maxlength="50"></textarea>  
                                </div>
                                <div>
                                    <label for="">录取专业:</label>
                                    <textarea v-model="er" maxlength="50"></textarea>  
                                </div>
                            </div>
                            <textarea name="" id="" style="width:100%;height:90px" v-model="ct" v-else></textarea>
                           </slot>
                            <div class="btn-group flex-between" :class="{'end':row.ue===10204}">
                                <div class="flex-between" v-if="(row.ue!=10204)">
                                    <div class="btn search" style="margin-right:10px;margin-left: 0;" 
                                    @click="changeCt(item.quickContent)"
                                    v-for="item in callQuick" :key="item.quickContent">{{item.quickContent}}</div>
                                </div>
                                <div class="btn search"  @click="submit">提交</div>
                            </div>
                        </div>
                        </div>
                    <div class="rt">
                        <div class="sub">
                            <img src="../assets/images/tjhs.png" alt=""><span>推荐话术</span>
                        </div>
                        <div v-html="msg"></div>
                    </div>
                </div>
            </div>
            
        </div>
</template>

<script>

import { getCallQuick, updateRemarks,updateCallRecord ,saveEnterSchool,getSpeechcraft} from "@/api"

export default {
  props: {
    row: {
      type: Object,
      default:null
    },
    type:Number
  },
  data() {
    return {
      v2:'',
      msg: '',
      time: '',
      hour: 0,
      min: 0,
      sec: 0,
      beginCount: false,
      timer: null,
      callQuick: [],
      ct: '',
      recordState: '',
      isShow: true,
      el: '',
      er:''
    };
  },
  filters: {
    filterStr(val) {
      return val < 10 ? '0' + val : val
    }
  },
  created() {
    this.ct = ''
    clearInterval(this.timer)
    this.sec=''
    this.min=''
    this.hour=''
    getCallQuick().then(res => {
      this.callQuick=res.obj.callQuick
    })
    
    getSpeechcraft({ce:this.type}).then((res) => {
      this.msg = res.obj
    });
  },
  methods: {
    changeCt(content) {
      this.ct+=content
    },
    callItem() {
      this.$store.commit('changeCallData', this.row)
     
      let status = window.workbench.getStatusCode()
    
      if (status.code==-1) {
        return this.$Message.info('当前坐席未注册，请点击上线注册')
      }
      // window.workbench.call('13944146512')
      window.workbench.call(this.row.account);
    },
    submit() {
      if (this.$store.state.recordId ==='') {
        return this.$Message.error('未通话，无法保存通化小结')
      }
     
      // 录取客服保存录取院校专业
      if (this.row.ue === 10204) {
        if (this.el==='' || this.er==='') {
          return this.$Message.error('请填写录取院校专业')
        }
        saveEnterSchool({
          wid:this.row.userid,
          el:this.el,
          er:this.er,
        }).then(() => {
          this.ct=this.el+';'+this.er
          this.updateRemarks()
        })
      } else {
        this.updateRemarks()
      }
    },
    closeDialog() {
      if (this.timer!=null) {
        window.workbench.hangUp()  
      }
      this.$emit('close')
    },
    updateRemarks() {    
      if (this.ct ==='') {
        return this.$Message.error('请填写通话小结')
      }                                                                                       
      updateRemarks({
        id: this.$store.state.recordId,
        ct:this.ct
      }).then(res => {
        if (this.timer!=null) {
          window.workbench.hangUp()  
        }
        this.$store.commit('setRecordId','')
        this.$store.commit('beginCountTime', false)
        this.$emit('close')
      })
    },
    countTime() {
      this.sec++;
      if (this.sec>59) {
        this.min++;  
        this.sec=0
      }
      if (this.min>59) {
        this.hour++;  
        this.min=0
      }
    },
    // 电话接通 开始计时
    beginCountTime() {
      this.isShow=false
      this.beginCount=true
      this.timer=setInterval(() => {
        this.countTime()
      },1000)
    },
    // 电话结束 update
    updateCallRecord() {
      clearInterval(this.timer)
      const payload=this.$store.state.updateCallDetail
      this.isShow=false
      let cs;
      if (payload.callContext.channelContexts[1].channelState=='ANSWERED') {
        cs=200
      } else {
        cs=payload.callContext.channelContexts[1].releaseReason.slice(0,3)  
      }
      updateCallRecord({
          id: this.$store.state.recordId,
          ct: this.toSeconds,//通话时长
          cs
        }).then(res => {
          this.recordState=res.obj
        })
    }
  },
  computed: {
    toSeconds() {
      return this.sec*1000 + this.min*60*1000 + this.hour*60*60*1000
    },
    updateState() {
      return this.$store.state.updateCallDetail
    },
    beginCountT() {
      return this.$store.state.beginCount
    }
  },
  watch: {
    updateState: {
      handler: function (newval, oldval) {
        this.updateCallRecord()
      },
      deep:true
    },
    beginCountT:function(newval) {
      if (newval === true) {
        this.beginCountTime()
      }
    }
  }

  
}
</script>
<style lang='scss' scoped>
    .outer {
            background: #f4f5fc;
            padding: 3px;
            border-radius: 10px;
            cursor: pointer;
            .end {
              justify-content: flex-end;
            }
            .title {
                height: 39px;
                line-height: 39px;
                background: #747bad;
                color: #fff;
                padding: 0 10px;
                border-radius: 10px 10px 0 0;
                margin-bottom: 10px;
                font-size: 16px;
            }
            .box {
                padding: 0 10px;
                .sub {
                    border-bottom: 1px solid #969cc2;
                    padding-bottom: 5px;
                    span {
                        font-size: 16px;
                        font-weight: bold;
                    }
                    img {
                        vertical-align: middle;
                        margin-right: 10px;
                    }
                }
                ul {
                    li {
                        display: flex;
                        justify-content: space-between;
                        font-size: 16px;
                        border-bottom: 1px solid #747bad;
                        padding: 9px 0;
                    }
                }
            }
            .lf {
                .top {
                    margin-bottom: 10px;
                    &>div {
                        border: 1px solid #969cc2;
                        border-radius: 10px;
                        padding: 10px;
                    }
                    .l {
                        height: 430px;
                        width: 308px;
                        margin-right: 10px;
                    }
                    .r {
                        height: 430px;
                        width: 320px;
                        overflow: hidden;
                        .time {
                            margin:20px 0;
                        }
                        .btn {
                            background: red;
                            margin-left: 0;
                            color: #fff;
                        }
                        .tel {
                            margin-left: 15px;
                            p {
                                margin: 5px 0;
                            }
                        }
                        div {
                            font-size: 14px;
                        }
                    }
                }
                .bot {
                    width: 100%;
                    height: 170px;
                    border: 1px solid #969cc2;
                    border-radius: 10px;
                    padding: 10px;
                    .xj {
                        height: 85px;
                        margin-top: 10px;
                        div {
                            margin-bottom: 10px;
                            display: flex;
                            align-items: flex-start;
                            label {
                                font-size: 14px;
                                margin-right: 10px;
                            }
                            textarea {
                                width: 450px;
                                height: 30px;
                                line-height: 1.3;
                            }
                        }
                    }
                }
            }
            .rt {
                height: 620px;
                width: 372px;
                border: 1px solid #969cc2;
                border-radius: 10px;
                padding: 10px;
                margin-left: 10px;
                p {
                    font-size: 14px;
                    line-height: 1.5;
                    margin: 10px 0;
                }
            }
        }
        textarea {
            border:none;
            outline: none;
            background-color: transparent;
            color: #333;
            font-size: 14px;
            line-height: 1.5;
            resize: none;
        }
</style>