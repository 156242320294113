<!--
 * @Author: jjx 1432652396@qq.com
 * @Date: 2022-11-21 13:28:00
 * @LastEditors: jjx 1432652396@qq.com
 * @LastEditTime: 2022-12-01 11:07:22
 * @FilePath: \windowshawncrm\src\components\workBench.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
    <div class=''>
        <div @click="openWorkbench()" class="mini-workbench">云呼</div>
        <div id="workbench"></div>
    </div>
</template>

<script>
export default {
components: {},
data() {
    return {

    };
},
methods: {
    openWorkbench(){
        var workbenchWrapper = document.querySelector("#workbench");
        var floater = document.querySelector(".mini-workbench");
        window.workbench.changeVisible(window.workbenchOpen);
        if (!window.workbenchOpen) {
          workbenchWrapper.style.display = 'none';
          floater.innerHTML = '云呼';
        } else {
          workbenchWrapper.style.display = 'block';
          floater.innerHTML = 'X';
        }
        window.workbenchOpen = !window.workbenchOpen
      }
},

  mounted() {
      const self=this
      window.workbench = new window.WorkbenchSdk({
        dom: 'workbench',
        regionId: 'cn-shanghai',
        instanceId: 'winshawn',
        ajaxOrigin:process.env.VUE_APP_BASE_API,
        ajaxPath: '/api/aliyun/ccc/api',
        ajaxMethod: 'post',
        // withCredentials:true,
        ajaxOtherParams: {
          ui:this.$store.state.userInfo.id
        },
        width: 320,
        height:430,
        header: true,
        useOpenApiSdk: false,
        changeMainPaneVisible:false,
        exportErrorOfApi:true,
        sideNavList: ["呼出", "通话记录", "我的工作", "设备检测", "设置"],
        onCallDialing: function (e) {
          // console.log('开始呼叫', e)
          self.$store.dispatch('saveCallRecord',e) // 保存jobID
         
        },
        onCallEstablish: function (callDetail) {
          console.log("这里是通话建立时触发的回调函数", callDetail)
          // self.$emit('beginCountTime', true);
          self.$store.commit('beginCountTime',true)
        },
        onCallRelease: function (callDetail) {
          // console.log("这里是通话结束时触发的回调函数", callDetail)
          // self.$emit('updateCallRecord', callDetail);
          self.$store.commit('updateCallRecord',callDetail)
        }
      })
      
      //面板展示开关
      window.workbenchOpen =false
},
}
</script>
<style lang='scss'>
  // .components-Keyboard-___style__call-keyboard {
  //   display: none;
  // }
        .mini-workbench {
            position: fixed;
            height: 50px;
            line-height: 50px;
            width: 50px;
            text-align: center;
            background: #00c1de;
            color: white;
            cursor: pointer;
            bottom: 104px;
            right: 20px;
            border-radius: 50%;
        }


        #workbench {
            font-size: 14px;
            color: #4F5357;
            position: fixed;
            background: #FFFFFF;
            border: 1px solid #D7D8D9;
            box-shadow: 0 1px 4px 0 rgba(55, 61, 65, 0.14);
            left: 0;
            transition: all .3s;
            z-index:99999;
            width:320px;
            bottom: 20px;
        }
</style>