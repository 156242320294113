<!-- 推荐话术 -->
<template>
  <div class="layer">
    <div class="outer blockCenter">
      <div class="summaryvue">
        <div class="title flex-between">
          <span>推荐话术</span>
          <Icon
            type="ios-close"
            size="30"
            @click="$store.commit('recoScriptsVisible', false)"
          />
        </div>
        <div class="box" v-html="msg"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSpeechcraft } from "@/api";
export default {
  components: {},
  data() {
    return {
      msg: "",
    };
  },
  
  created() {
    getSpeechcraft({ce:this.$store.state.showRecoScripts.row}).then((res) => {
      this.msg = res.obj
    });
  },
};
</script>
<style lang="scss" scoped>
.outer {
  background: #f4f5fc;
  padding: 3px;
  border-radius: 10px;
  width: 800px;
  cursor: pointer;
  .title {
    height: 39px;
    line-height: 39px;
    background: #747bad;
    color: #fff;
    padding: 0 10px;
    border-radius: 10px 10px 0 0;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .box {
    height: 500px;
    padding: 10px;
    line-height: 1.5;
    overflow-y: auto;
    font-size: 14px;
  }
}
</style>
