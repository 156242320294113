<!--
 * @Author: jjx 1432652396@qq.com
 * @Date: 2022-11-03 15:14:17
 * @LastEditors: jjx 1432652396@qq.com
 * @LastEditTime: 2022-11-03 15:23:58
 * @FilePath: \winshawncrm\src\components\chart.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- echarts组件 -->
<template>
    <div :class="className" class="chart" :style="{'width':width,'height':height}"></div>
</template>

<script>
import echarts from "echarts"
export default {
    props:{
        className:{
            type:String
        },
        option:{
            type:Object
        },
        width:{
            type:String,
            default:'100%'
        },
        height:{
            type:String,
            default:'100%'
        }
    },
    data() {
        return {
            chart:null
        }
    },
    watch: {
        option:{
            handler:function(newVal,oldVal){
                if(newVal){
                    this.setChart()
                }
            },
            deep:true
        }
    },
    methods: {
        setChart(){
            let charts = document.querySelector('.' + this.className)
            if (charts) {
                charts = [charts]
            } else {
                charts = []
            }
            for (let i = 0; i < charts.length; i++) {
                this.chart = echarts.init(charts[i])
                this.chart.setOption(this.option)
              }
              this.$nextTick(() => {
                window.addEventListener('resize',this.resizeChart)
              })
          },
          resizeChart() {
            if (this.chart) {
              this.chart.resize();
            }
        }
    },
    mounted() {
        this.setChart()
        
    },
    beforeDestroy(){
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart.clear()
        this.chart = null
        window.removeEventListener('resize',this.resizeChart)
    }
}
</script>
<style lang='scss' scoped>
    .chart {
        width: 100%;
        height: 100%;
    }
</style>