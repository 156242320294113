/*
 * @Author: jjx 1432652396@qq.com
 * @Date: 2022-10-31 09:49:22
 * @LastEditors: jjx 1432652396@qq.com
 * @LastEditTime: 2022-12-05 16:18:02
 * @FilePath: \winshawncrm\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/flexible.js"
import iView from 'iview';
import 'iview/dist/styles/iview.css';

// import "@/assets/common.scss";

Vue.use(iView);

Vue.config.productionTip = false

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor);

import VueClipBoard from 'vue-clipboard2'
 
Vue.use(VueClipBoard)
const whiteList = ['/login'] // no redirect whitelist
router.beforeEach(async (to, from, next) => {
  if(localStorage.getItem("tk")){
    if(to.path=="/login"){
      next('/')
    } else {
      if (store.state.userInfo.userName) {
        next()
      } else {
        if (store.state.visitedList.length === 0) {
          let list = localStorage.getItem('visitedList')
          if (list!=null) {
            store.commit('setStoreList',JSON.parse(list))
          }
        }
        store.dispatch('getUserInfo')
        next()
      }
    }
  }else{
    if(whiteList.indexOf(to.path) !== -1){
      next()
    } else {
      router.push({
        path:`/login?redirect=${to.fullPath}`
      })
    }
  }
})




Vue.filter('sliceStr', function (val, len) {
  if (val) {
    return val.slice(0,3)+'****'+val.slice(7)
  }
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
