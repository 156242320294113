
<template>
    <ul class="nav">
        <li v-for="item,index in navs" :key="item.name" :class="{'active':cur==index}" @click="changeUrl(item,index)">
            <div>
                <img :src="item.icons.slice(0,(item.icons.lastIndexOf('.')))+'1.png'" width="20" v-if="cur==index" />
                <img :src="item.icons" width="20" v-else />
                {{item.name}}
            </div>
        </li>
        </ul>
</template>

<script>
import {permission} from "@/api"
export default {
components: {},
data() {
    return {
        cur:0,
        navs:[],
    };
},
watch:{
    $route: {
      handler: function (newVal) {
        let i = this.navs.findIndex(item => item.url == newVal.path);
        if (i != -1) {
          this.cur=i
        }
      },
      deep:true
    }
  },

  methods: {
    initTags(){
      let { name } = this.$route;
      let navsArr = this.navs.map(item => item.name)
      let localArr = localStorage.getItem('visitedList') != null && JSON.parse(localStorage.getItem('visitedList')).map(item => item.name)
      let flag=true
      if (localArr.length > 0) {
        for (let i = 0; i < localArr.length;i++){
          if (navsArr.indexOf(localArr[i]) == -1) {
            flag=false
          } else {
            flag=true
         }
        }
      }
      
      if (!flag) {
        this.$store.commit('setStoreList',[])
        this.$router.push( { path:this.navs[0].url})
        this.$store.commit('addList', { ...this.navs[0] })
      } else {
        this.$store.commit('addList', { name: name, url: this.$route.path })
      }
      
    },
    async changeUrl(item, index) {
      if (localStorage.getItem('userName') != this.$store.state.userInfo.userName) {
        let res = await permission().then(res=>{
            this.navs=res.obj.permissions;
        })
        this.$store.dispatch('getUserInfo')
        this.$store.commit('setStoreList',[])
        this.$router.push(
          {
            path:this.navs[0].url
          }
        )
        window.location.reload()
        this.$store.commit('addList', { ...this.navs[0] })
        return
      }
      this.$store.commit('addList', item)
        this.cur=index;
        this.$router.push(
            {
              path:item.url
            }
        )
    }
  },
  async created() {
    let res = await permission().then(res=>{
        this.navs=res.obj.permissions;
    })
    let i = this.navs.findIndex(item => item.url == this.$route.path);
    
    if (i != -1) {
        this.cur=i
    }
    this.initTags()
}
}
</script>
<style lang='scss' scoped>
 .nav {
    cursor: pointer;
    flex:0 0 148px;
    height: 100%;
    border-radius: 0 15px 0 0;
    overflow: hidden;
    border: 1px solid #fff;
    li {
        padding: 0 10px;
        color: #525252;
        div {
        border-bottom: 1px solid #bdbbbb;
        font-size: 15px;
        padding:20px 0;
        }
        img {
            vertical-align: middle;
            margin-right: 10px;
        }
        &.active {
            background: #747bad;
            position: relative;
            border-bottom: 1px solid #bdbbbb;
        &:before {
            position: absolute;
            content: '';
            display: block;
            width: 5px;
            height: 100%;
            border-radius: 3px;
            background: #3a437f;
            left: 0;
        }
        div {
            color: #fff;
            border: none;
        }
        }
    }
    }
</style>