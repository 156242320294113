<!--
 * @Author: jjx 1432652396@qq.com
 * @Date: 2022-10-31 09:49:22
 * @LastEditors: jjx 1432652396@qq.com
 * @LastEditTime: 2022-11-30 08:26:19
 * @FilePath: \winshawncrm\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <router-view/>
    <!-- 查看通话小结 -->
    <summaryVue v-if="$store.state.summaryAll.showSummary" :row="$store.state.summaryAll.row"/>

    <!-- 查看录音 -->
    <sound  v-if="$store.state.soundAll.showSound" :row="$store.state.soundAll.row"/>

    <!-- 推荐话术 -->
    <recommendScripts v-if="$store.state.showRecoScripts.showReco" :row="$store.state.showRecoScripts.row"/>

    <!-- 呼叫 登录后客服显示-->  
    <workbench v-if="$store.state.login&&$store.state.userInfo.roleId==54"/>
    
  </div>
</template>
<script>
import summaryVue from "@/components/summary.vue"
import sound from "@/components/sound.vue"
import recommendScripts from "@/components/recoScripts.vue"
import workbench from '@/components/workBench.vue';
export default {
  components: {
    summaryVue,sound,recommendScripts,workbench
  }
}
</script>

<style lang="scss">
 @import "@/assets/common.scss";
  ul,li {
    list-style: none;
  }
  a {
    text-decoration: none;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: microsoft yahei;
    line-height: 1;
  }
  body {
    background: url("./assets/images/bg.png") repeat scroll!important;
  }
  #app {
    width: 100vw;
    height: 100vh;
    user-select: none;
  }
  i {
    font-style: normal;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .between {
    justify-content: space-between;
  }
  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  input {
    outline: none;
    border: none;
  }
</style>
