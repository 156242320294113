<!--
 * @Author: jjx 1432652396@qq.com
 * @Date: 2022-11-03 08:44:29
 * @LastEditors: jjx 1432652396@qq.com
 * @LastEditTime: 2022-12-02 15:26:05
 * @FilePath: \winshawncrm\src\views\addFeedBack.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
    <div class='addfeedback'>
        <div class="flex">
            <label><i>*</i>请选择问题类型:</label>
            <ul class="flex">
                <li v-for="item,index in feedbackType" :key="item.name" :class="{'active':cur==index}" @click="cur=index;fe=item.id">
                    {{item.name}}
                    <img v-if="cur==index" src="../assets/images/active.png" alt="">
                </li>
            </ul>
        </div>
        <div class="flex mb">
            <label><i>*</i>标题:</label>
            <Input v-model="te" :maxlength=50 />
            <span>标题输入限制50个字</span>
        </div>
        <div class="flex mb">
            <label><i>*</i>功能:</label>
            <Input v-model="ms" :maxlength=100 />
            <span>推荐功能：<i>志愿推荐</i><i>国内高校</i><i>国内专业</i></span>
        </div>
        <div class="flex">
            <label><i>*</i>内容:</label>
            <VueQuillEditor v-model="ce"/>  
        </div>
        <div class="flex mb">
            <label>上传附件:</label>
            <Upload action="#"
            accept=".png,.jpg,.gif,.jpeg,.pdf,.doc,.txt,.avi,.docx"
            :show-upload-list="true"
            :before-upload="handleUpload">
                <Button icon="ios-cloud-upload-outline">点击上传</Button>
            </Upload>
            <span>选择文件，允许的文件类型：png，jpg，gif，jpeg，pdf，doc，txt，avi，mp4,docx且不超过50MB</span>
        </div>
        <div class="flex" v-if="file"><label>已选附件:</label>{{file.name}}</div>
        <div class="flex mb">
            <label for=""></label>
            <div class="btn search" @click="feedbacksave">提交</div>
        </div>
    </div>
</template>

<script>
import VueQuillEditor from '@/components/quill-editor-template';
import {getType,feedbacksave} from "@/api"
export default {
    components: {},
    data() {
        return {
          feedbackType:[],
          cur:0,
          file:null,
          fe:'',
          te:'',
          ms:'',
          ce: ''
        };
    },
    components: {
        VueQuillEditor
    },
    methods: {
        feedbacksave(){
            if(this.te==''||this.ms==''||this.ce==''){
                return this.$Message.info('请填写完整');
            }
            let formData = new FormData();
            if (this.file!=null) {
              formData.append('file', this.file);
            }
            formData.append('fe', this.fe);
            formData.append('te', this.te);
            formData.append('ms', this.ms);
            formData.append('ce', this.ce);
            feedbacksave(formData).then(res=>{
                this.$Message.success('提交成功');
                this.$router.push({
                    path:'/submission'
                })
            })
        },
      handleUpload(file) {
        console.log(file)
        if (file.size>50*1024*1024) {
          this.$Message.error('文件大于50MB,重新上传')
          this.file = null;
            return
          }
          this.file = file;
          return false;
      }
    },
    created() {
        getType().then(res=>{
            this.feedbackType=res.obj.feedbackType
            this.fe= this.feedbackType[0].id
        })
    },
}
</script>
<style lang='scss' scoped>
    .addfeedback {
        width: 1120px;
        margin: 20px auto;
        &>div {
            margin-bottom: 30px;
            position: relative;
            align-items: flex-start;
            span {
                position: absolute;
                bottom: -20px;
                left: 150px;
                color:#919191;
                i {
                    color: #333;
                    margin-right: 10px;
                }
            }
            label {
                text-align: right;
                flex:0 0 132px;
                font-size: 14px;
                margin-right: 18px;
                color: #525252;
                i {
                    color: red;
                    margin-right: 5px;
                    vertical-align: middle;
                    font-size: 12px;
                }
            }
        }
        .mb {
            margin-bottom: 50px;
        }
        ul {
            li {
                border: 1px solid #ccc;
                height: 42px;
                text-align: center;
                line-height: 42px;
                border-radius: 13px;
                font-size: 14px;
                color:#989899;
                width: 125px;
                margin-right: 100px;
                cursor: pointer;
                background: #fff;
                position: relative;
                overflow: hidden;
                &.active {
                    border-color: #747bad;
                    img {
                        position: absolute;
                        top:0;
                        right: 0;
                        width: 20%;
                    }
                }
            }
        }
        .btn {
            margin-left: 0;
            font-size: 15px;
            padding: 10px 30px;
        }
    }
</style>