<template>
    <div class="box">
        <img src="../assets/images/logo.png" class="logo">
        <div class='form blockCenter'>
            <p class="title tc">登录</p>
            <div class="formItem">
                <img src="../assets/images/iphone.png" width="20">
                <label>登录账号</label>
                <input type="text" v-model="un" minlength="1" maxlength="20">
            </div>
            <div class="formItem">
                <img src="../assets/images/password.png" width="20">
                <label>登录密码</label>
                <input type="password" v-model="pw" minlength="1" maxlength="24"/>
            </div>
            <div class="formItem">
                <img src="../assets/images/msg.png" width="20">
                <label class="keep">验证码</label>
                <input type="text" v-model="code" class="yzm" maxlength="4" minlength="4" @keyup.enter="toLogin" />
                <div @click="refreshCode" class="code" title="点击切换验证码">
                    <s-identify :identifyCode="identifyCode"></s-identify>
                </div>
            </div>
            <input type="button" value="登录" class="login" @click="toLogin" :disabled="!canClick" :class="{'active':canClick}">
        </div>
    </div>
</template>

<script>
import sIdentify from "@/components/identify.vue";
import {login} from "@/api"
export default {
components: {sIdentify},
data() {
    return {
        tel:'',
        identifyCode: "",
        identifyCodes: ['0','1','2','3','4','5','6','7','8','9','a','b','c','d'],
        un:'',
        pw:'',
      code: '',
      redirect:''
    };
},
computed:{
    canClick(){
        return this.un!=''&&this.pw!=''&&this.code!=''
    }
},
  created() {
    this.refreshCode()
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
methods: {
        // 生成随机数
        randomNum(min, max) {
        max = max + 1
        return Math.floor(Math.random() * (max - min) + min);
        },
        // 更新验证码
        refreshCode() {
            this.identifyCode = "";
            this.makeCode(this.identifyCodes, 4);
        },
        // 随机生成验证码字符串
        makeCode(data, len) {
            for (let i = 0; i < len; i++) {
                this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length-1)]
            }
        },
        toLogin(){
            if(/[\u4E00-\u9FA5]/g.test(this.pw)){
                return this.$Message.info('密码不能有中文'); 
            }
          if (this.code != this.identifyCode) {
              this.refreshCode();
              this.code=''
              return this.$Message.info('图形验证码错误'); 
                
            }
            login({
                un:this.un,pw:this.pw
            }).then(res => {
              this.$store.dispatch('getUserInfo')
              localStorage.setItem('tk', res.obj.token);
              if (this.redirect) {
                this.$router.push({
                    path:this.redirect
                })  
              } else {
                this.$router.push({
                  path:"/"
                })
              }
              this.$store.commit('setLoginState',true)  
            }).catch(err=>{ 
                this.refreshCode();
                this.code=''
            })
        }
    }
}
</script>
<style lang='scss' scoped>
    .box {
        width: 100vw;
        height: 100vh;
        background: url("../assets/images/bg.png") no-repeat center;
        background-size: 100% 100%;
    }
    .logo {
        position: absolute;
        left: 19px;
        top: 19px;
    }
    .form {
        background: #fff;
        width: 368px;
        border-radius: 22px;
        padding: 46px;
        height: 538px;
        position: relative;
        .title {
            font-weight: bold;
            font-size: 23px;
            letter-spacing: 5px;
            color: #000;
            margin-bottom: 50px;
        }
        .formItem {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            input {
                border-bottom: 1px solid #b0b0b0;
                width: 165px;
            }
            label {
                font-size: 16px;
                margin: 0 10px;
                width: 70px;
            }
            .code {
                cursor: pointer;
            }
            .yzm {
                width: 60px;
                margin-right: 10px;
            }
        }
        .login {
            height: 47px;
            line-height: 47px;
            color: #fff;
            display: block;
            font-size: 16px;
            border-radius: 10px;
            position: absolute;
            bottom: 24px;
            left: 46px;
            width: 275px;
            cursor: pointer;
            background: #dfdfdf;
            &.active {
                background: #4268f0;
            }
        }
    }
</style>