<!--
 * @Author: jjx 1432652396@qq.com
 * @Date: 2022-11-01 10:35:06
 * @LastEditors: jjx 1432652396@qq.com
 * @LastEditTime: 2022-12-05 15:21:38
 * @FilePath: \winshawncrm\src\views\firstuse.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
    <div class='common'>
         <div class="options flex-between">
            <div class="optionItem flex">
            <p>客户类型</p>
            <Select v-model="applyData.ue">
                <Option v-for="item in res.userTypes" :value="item.id" :key="item.id">{{ item.title }}</Option>
            </Select>
            </div>
            <div class="optionItem flex region">
            <p>所在区域</p>
            <Select v-model="applyData.pe" @on-change="changeProvince">
                <Option value="0" :key="0">全部</Option>
                <Option v-for="item in res.provinces" :value="item.id" :key="item.id">{{ item.province }}</Option>
            </Select>
            <Select v-model="applyData.cy">
                <Option value="0" :key="0">全部</Option>
                <Option v-for="item in citys" :value="item.id" :key="item.city">{{ item.city }}</Option>
            </Select>
            </div>
            <div class="optionItem flex">
            <p>呼叫状态</p>
            <Select v-model="applyData.cs">
                <Option :value="0" :key="0">全部</Option>
                <Option v-for="item in res.callStatus" :value="item.id" :key="item.id">{{ item.title }}</Option>
            </Select>
            </div>
            <div class="optionItem flex">
            <p>客户账号</p>
            <Input v-model="applyData.at" placeholder="请输入"/>
            </div>
            <div class="optionItem flex">
                <p>选择日期</p>
                <DatePicker type="daterange" split-panels placeholder="选择日期" format="yyyy-MM-dd" v-model="date"></DatePicker>
              </div>
            <div>
            <Icon type="ios-refresh" size="32" style="font-weight:bold;margin-left: 60px;cursor: pointer;" @click="payCustomer"/>
            <input type="button" value="重置" class="btn" @click="reset">
            <input type="button" value="查询" class="btn search" @click="search">
            </div>
        </div>
        <div class="table-wrapper-box">
        <div class="table-container">
            <Table :columns="columns" :data="list" border>
              <template slot-scope="{ row }" slot="account">
                {{ row.account | sliceStr }}
              </template>
              <template slot-scope="{row}"  slot="hj">
                    <img src="../assets/images/hj.png" alt="" @click="showDialogCall(row)">
                </template>
                <template slot-scope="{row}"  slot="lx">
                    <img src="../assets/images/lx.png" alt="" @click="showSoundDialog(row)">
                </template>
                <template slot-scope="{row}"  slot="hs">
                    <img src="../assets/images/hs.png" alt="" @click="showScriptsVisibleDialog">
                </template>
                <template slot-scope="{row}"  slot="xj">
                    <img src="../assets/images/xj.png" alt="" @click="showSummaryDialog(row)">
                </template>
            </Table>
        </div>
        <Page :total="total" :page-size="lt" :current="of" show-total show-elevator style="float:right" @on-change="changePage" @on-page-size-change="changeSize"/>
        </div>
          <!-- 呼叫 -->
        <callVue
          :row="row"
          v-if="showCall"
          :type="1003"
          @close="
            showCall = false;
            payCustomer();
          "
        />
    </div>
</template>

<script>
import {payCustomer,payCustomerSearch} from "@/api"
import callVue from "@/components/call.vue"
export default {
components: {},
data() {
    return {
        columns: [
          {
              title: '序号',
              key: 'userid'
          },
          {
              title: '用户名',
              key: 'account',
              slot: "account",
          },
          {
              title: '省份',
              key: 'province'
          },
          {
              title: '科类',
              key: 'subjects'
          },
          {
              title: '分数',
              key: 'score'
          },
          {
              title: '支付功能',
              key: 'payModule'
          },
          {
              title: '支付金额',
              key: 'payMoney'
          },
          {
              title: '呼叫',
              key: 'age',
              slot:'hj'
              
          },
          {
              title: '推荐话术',
              key: 'address',
              slot:'hs'
          },
          {
              title: '录音',
              key: 'name',
              slot:'lx'
          },
          {
              title: '通话状态',
              key: 'callStatus'
          },
          {
              title: '通话时长',
              key: 'callTime'
          },
          {
              title: '通话小结',
              key: 'address',
              slot:'xj'
          }
      ],
      list: [],
        applyData:{
            ue:0,
            pe:'0',
            cy:'0',
            cs:'',
            at:'',
        },
        of:1,
            lt:10,
        total:0,
        res:{},
      citys: [],
      row: {},
      showCall: false,
      date:''

    };
},
components:{
    callVue
  },
  computed: {
    st() {
      if (this.date[0]) {
        return new Date(this.date[0]).getFullYear()+'-'+(new Date(this.date[0]).getMonth()+1)+'-'+new Date(this.date[0]).getDate()
      }
    },
    et() {
      if (this.date[1]) {
        return new Date(this.date[1]).getFullYear()+'-'+(new Date(this.date[1]).getMonth()+1)+'-'+new Date(this.date[1]).getDate()
      }
    },
  },
  methods: {
    // 1001：首用；1002：常用；1003：付费；1004：录取
    showScriptsVisibleDialog(){
      this.$store.commit('recoScriptsVisible', { row: 1003, showReco: true })
    },
    showDialogCall(row) {
      this.row = { ...row, ue: 10203 };
      this.showCall = true;
    },
    showSummaryDialog(row) {
      this.$store.commit("summaryVisible", { row: row, showSummary: true });
    },
    showSoundDialog(row) {
      this.$store.commit("soundVisible", { row: row, showSound: true });
    },
    payCustomer() {
      this.$Spin.show();
        payCustomer({...this.applyData,of:this.of,lt:this.lt,st:this.st,et:this.et}).then(res=>{
            this.list=res.obj.customers.records;
            this.total=res.obj.customers.total;
          this.$Spin.hide();
        }).catch(() => {
          this.$Spin.hide();
        })
    },
    reset() {
        Object.keys(this.applyData).map(key=>{
            this.applyData[key]=''
        })
        this.total=0;
        this.of=1;
        this.applyData.cs=this.res.callStatus[0].id;
        this.applyData.pe='0'
        this.applyData.cy = '0'
      this.setDefaultDate()
        this.payCustomer()
    },
    search(){
        this.of=1;
        this.payCustomer()
    },
    changePage(e){
        this.of=e;
        this.payCustomer()
    },
    changeSize(e){
        this.of=1
        this.lt=e;
        this.payCustomer()
    },
    // 切换省份
    changeProvince(e){
        if(e==0){
            this.citys=[];
            this.applyData.cy='0';
            return
        }
        let item=this.res.provinces.find(item=>item.id==e);
        this.applyData.cy='0';
        this.citys=item.citys
    },
    setDefaultDate() {
      const CURRENT_YEAR = new Date().getFullYear()
      const startDate = new Date(`${CURRENT_YEAR}-1-1`)
      this.date=[startDate,new Date()]
    }
},
  created() {
    this.setDefaultDate()
    payCustomerSearch().then(res=>{
        this.res=res.obj
        this.applyData.cs=this.res.callStatus[0].id
        this.payCustomer()
    })
},
}
</script>
<style lang='scss' scoped>
    .common {
        height: 100%;
    }
    ::v-deep .ivu-select-default {
        width: auto;
    }
    ::v-deep .ivu-input {
        width: auto;
    }
    .region {
        .ivu-select-default {
            width: 103px;
        }
    }
        .table-container {
            height: calc(100% - 40px);
            cursor: pointer;
        }
</style>