<!--  -->
<template>
    <div class="layer">
        <div class="outer blockCenter">
            <div class='summaryvue'>
                <div class="title flex-between">
                    <span>通话小结</span>
                    <Icon type="ios-close" size="30" @click="close"/>
                </div>
                <div class="box flex-between">
                  <div class="lf" v-if="$store.state.summaryAll.row.id">
                    <ul>
                        <li v-for="item,index in record" :key="item" @click="changeAccount(index)" :class="{'active':accIndex==index}"><img :src="accIndex==index?require('../assets/images/white.png'):require('../assets/images/teltx.png')" /><span>{{item.account|sliceTr}}</span></li>
                    </ul>
                </div>
                    <div class="mid">
                        <img src="../assets/images/teltx.png" alt="">
                        <div>
                            <span>{{account|sliceStr}}</span>
                            <div>通话时间</div>
                        </div>
                        <ol v-if="summaryAll.row.account">
                            <li v-for="item,index in record" :key="index" @click="changeCur(index)" :class="{'active':cur==index}">{{item.createTime}}</li>
                        </ol>
                        <ol v-else>
                            <li v-for="item,index in statisticsRecord" :key="index" @click="changeCur(index)" :class="{'active':cur==index}">{{item.createTime}}</li>
                        </ol>
                    </div>
                    <div class="rt">
                        <textarea name="" id="" style="resize:none" v-model="callContent" readonly></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {remarks,statisticsRemarks} from "@/api"
export default {
  components: {},
    data() {
        return {
          record: [],
          callContent: '',
          cur: 0,
          statisticsRecord: [],
          accIndex:0
        }
    },
    methods: {
      changeCur(index) {
        this.cur = index;
        if (this.summaryAll.row.id) {
          this.callContent=this.record[this.accIndex].call_record[index].callContent
         } else {
          this.callContent=this.record[index].callContent
        }
        
      },
      close() {
        this.$store.commit('summaryVisible', {row:{},showSummary:false})
      },
      changeAccount(index) {
        this.accIndex = index
        this.cur = 0;
        this.account=this.record[index].account
        this.callContent=this.record[index].call_record[0].callContent
        this.statisticsRecord=this.record[index].call_record
      }
  },
  computed: {
    summaryAll() {
      return this.$store.state.summaryAll
    }
  },
  created() {
    if (this.summaryAll.row.id) { // 客服管理统计
      statisticsRemarks({sui:this.summaryAll.row.id}).then(res => {
        if (res.obj!=null) {
          this.record = res.obj.record;
          this.callContent=this.record[0].call_record[0].callContent
          this.statisticsRecord = this.record[0].call_record;
          this.account=this.record[0].account
        }
      })
    } else { // 列表小结
      remarks({wui:this.summaryAll.row.userid}).then(res => {
        if (res.obj!=null) {
          this.record = res.obj.record;
          this.callContent = this.record[0].callContent
          this.account=this.summaryAll.row.account
        }
      })
    }
    }
}
</script>
<style lang='scss' scoped>
.outer {
        background: #f4f5fc;
        padding: 3px;
        border-radius: 10px;
        width: 953px;
        height: 539px;
        cursor: pointer;
        .title {
            height: 39px;
            line-height: 39px;
            background: #747bad;
            color: #fff;
            padding: 0 10px;
            border-radius: 10px 10px 0 0;
            margin-bottom: 10px;
            font-size: 16px;
        }
        .box {
            padding: 0 10px;
            &>div {
                border: 1px solid #747bad;
                height: 475px;
                border-radius: 10px;
                overflow: hidden;
            }
            li {
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 10px;
                border-bottom: 1px solid #747bad;
                font-size: 15px;
                img {
                    width: 20px;
                }
                span {
                    font-size: 15px;
                }
                &.active {
                    background: #747bad;
                    color: #fff;
                }
            }
            .lf {
                width: 23%;
                overflow: hidden;
                ul {
                  height: 475px;
                  overflow: auto;
                }
            }
            .mid {
                width: 30%;
                margin:0 1%;
                img {
                    width: 20%;
                    display: block;
                    margin: 20px auto;
                }
                div {
                    margin: 10px;
                    font-size: 16px;
                    text-align: center;
                }
                ol {
                  height: 353px;
                  overflow: auto;
                  
                }
            }
            .rt {
                flex:1;
                padding: 10px;
                line-height: 1.5;
                textarea {
                    background: none;
                    outline: none;
                    border: none;
                    width: 100%;
                    height: 100%;
                    overflow: auto;
                }
            }
        }
        textarea {
            font-size: 15px;
            color:#333;
            white-space: pre-wrap;
        }
    }
    ul::-webkit-scrollbar ,ol::-webkit-scrollbar {display:none}
</style>