<!--  -->
<template>
    <div class='submission'>
        <div class="flex selection">
            <Select v-model="fe" placeholder="搜索问题类型">
                <Option v-for="item in feedbackType" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
            <Input v-model="un" placeholder="搜索客服姓名"  :maxlength="10"/>
            <input type="button" value="重置" class="btn" @click="reset"/>
            <input type="button" value="搜索" class="btn search" @click="search"/>
            <input type="button" value="新增" class="btn search" @click="$router.push({path:'/addFeedBack'})"/>
        </div>
        <div class="table-main">
            <Table :columns="columns" :data="list">
                <template slot-scope="{row}"  slot="file">
                    <div v-if="row.filePath" class="down"><a :href="row.filePath">下载</a></div>
                </template>
                <template slot-scope="{row}"  slot="title">
                    <div @click="rowClick(row)">{{row.title|sliceStr(20)}}</div>
                </template>
                <template slot-scope="{row}"  slot="content">
                    <div v-html="row.sliceCon" @click="rowClick(row)"></div>
                </template>
                <template slot-scope="{row}"  slot="modulars">
                    <div @click="rowClick(row)">{{row.modulars|sliceStr(20)}}</div>
                </template>
            </Table>
        </div>
        <Page :total="total" :page-size="lt" :current="of" show-total show-elevator style="float:right" @on-change="changePage" @on-page-size-change="changeSize"/>
        <div class="layer" v-if="isShow">
            <div class="inner blockCenter">
                <Icon type="ios-close" size="30" class="close" @click="isShow=false;"/>
                <div class="flex">
                    <label for="">标题:</label>
                    <div  style="line-height: 1.5;">{{row.title}}</div>
                </div>
                <div class="flex">
                    <label for="">功能:</label>
                    <div  style="line-height: 1.5;">{{row.modulars}}</div>
                </div>
                <div class="flex">
                    <label for="">内容:</label>
                    <div v-html="row.content"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<!-- 用户为超级管理员和管理员时，隐藏坐席ID和主叫号码；用户为客服时，显示坐席ID和主叫号码，且必填； -->
<script>
import {getType,feedListPage } from "@/api"
export default {
components: {},
data() {
return {
    of:1,
    list:[],
    total:0,
    isShow:false,
    row:{},
    columns:[
        {
            title: '序号',
            type: 'index'
        },
        {
            title: '客服姓名',
            key: 'userName'
        },
        {
            title: '提交时间',
            key: 'gmtCreate'
        },
        {
            title: '问题类型',
            key: 'feedbackType'
        },
        {
            title: '标题',
            key: 'title',
            slot:'title'
        },
        {
            title: '功能',
            key: 'modulars',
            slot:'modulars'
        },
        {
            title: '内容',
            key: 'content',
            slot:'content'
        },
        {
            title: '上传附件',
            key:'file',
            slot: 'file'
        },
    ],
    feedbackType:[],
    fe:'',
    un:'',
    of:1,
    lt:13
};
},
filters:{
    sliceStr(name,len){
        if(name.length>len){
            return name.slice(0,len)+'...'
        }else {
            return name
        }
    }
},
methods: {
    listPage(){
        feedListPage({
            fe:this.fe,
            un:this.un,
            of:this.of,
            lt:this.lt
        }).then(res=>{
            res.obj.customers.records.map(item=>{
                item.sliceCon=item.content.length>20?item.content.slice(0,20)+'...':item.content
            })
            this.list=res.obj.customers.records;
            this.total=res.obj.customers.total
        })
    },
    reset(){
        this.fe=''
        this.un=''
        this.of=1;
        this.listPage()
    },
    search(){
        this.of=1;
        this.listPage()
    },
    changePage(e){
        this.of=e;
        this.listPage()
    },
    changeSize(e){
        this.of=1
        this.lt=e;
        this.listPage()
    },
  rowClick(row) {
        this.row=row;
        this.isShow=true
    }
},
created() {
    getType().then(res=>{
        this.feedbackType=res.obj.feedbackType
    })
    this.listPage()
}
}
</script>
<style lang='scss' scoped>
    .submission {
        height: 100%;
        border-radius: 10px;
        border: 1px solid #ddd;
        padding: 20px;
        .selection {
            height: 50px;
            margin-bottom: 10px;
            .ivu-select {
                margin-right: 30px;
            }
        }
        .table-main {
            height: calc(100% - 100px);
            cursor: pointer;
            overflow: auto;
        }
        .inner {
            border-radius: 5px;
            background: #eff1fa;
            padding: 40px 80px;
            max-width: 600px;
            position: relative;
            .close {
                position: absolute;
                right: 20px;
                top: 20px;;
                color: #525252;
                cursor: pointer;
            }
            label {
                font-size: 14px;
                color: #525252;
                margin-right: 20px;
                white-space: nowrap;
            }
            &>div {
                align-items: flex-start;
                margin-bottom: 30px;
            }
        }
        .btn-group {
            .btn {
                background: #747bad;
                color: #fff;
                display: inline-block;
                margin: 10px 20px 10px 0;
            }
            .ivu-icon {
                margin-right: 5px;
            }
        }
        .down {
            a {
                border-radius: 5px;
                background: #747bad;
                color:#fff;
                border-radius: 5px;
                display: inline-block;
                padding: 5px 12px;
            }
        }
     
    }
</style>
<style>
.ivu-select-single .ivu-select-selection {
  height: 40px!important;
  line-height: 40px!important;
}
</style>