<!--
 * @Author: jjx 1432652396@qq.com
 * @Date: 2022-11-01 14:43:28
 * @LastEditors: jjx 1432652396@qq.com
 * @LastEditTime: 2022-12-02 16:05:34
 * @FilePath: \winshawncrm\src\views\customerServiceManagement.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
    <div class='customer'>
        <div class="top flex">
            <div class="lf">
                <div class="flex-between">
                    <p class="title">每月完成情况</p>
                    <div class="select">
                        <Select v-model="ys" placeholder="年份" @on-change="monthCompletion">
                            <Option v-for="item in res.years" :value="item.id" :key="item.id">{{ item.name }}</Option>
                        </Select>
                        <Select v-model="ce" placeholder="客户类型" @on-change="monthCompletion">
                            <Option v-for="item in res.customerType" :value="item.id" :key="item.id">{{ item.name }}</Option>
                        </Select>
                    </div>
                </div>
                <div class="main"><chart :option="option" className='first-left'/></div>
                <chart/>
            </div>
            <div class="rt">
                <p class="title">当前完成情况</p>
                <div class="main"><chart :option="optionCur"  className='first-right'/></div>
            </div>
        </div>
        <div class="bottom">
            <div class="flex">
                <p class="title">客服详情</p>
                <Select v-model="os" placeholder="运营商选择" @on-change="changeOS">
                    <Option v-for="item in res.operators" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
                <Select v-model="ts" placeholder="日期"  @on-change="of=1;details()">
                    <Option v-for="item in res.timeSegments" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </div>
            <div class="main table-main">
                <Table :columns="columns" :data="list" :loading="loading" border>
                  <template slot-scope="{row}"  slot="lx">
                    <img src="../assets/images/lx.png" alt="" @click="showSoundDialog(row)">
                </template>
                  <template slot-scope="{ row }" slot="xj">
                    <img
                      src="../assets/images/xj.png"
                      alt=""
                      @click="showSummaryDialog(row)"
                    />
                  </template>
                </Table>
            </div>
            <Page :total="total" :page-size="lt" :current="of" show-total show-elevator style="float:right" @on-change="changePage" @on-page-size-change="changeSize"/>
        </div>
    </div>
</template>

<script>

import chart from "@/components/chart.vue"
import {search,monthCompletion,details,currentCompletion} from "@/api"
export default {
    components: {chart},
    data() {
        return {
            res:{},
            os:0,
            ts:'',
            ys:'',
            ce:'',
            option:{},
            optionCur:{},
            of:1,
            lt:10,
            columns:[
                {
                    title: '序号',
                    type: 'index'
                },
                {
                    title: '客服姓名',
                    key: 'userName'
                },
                {
                    title: '有效呼出数',
                    key: 'validCallNum'
                },
                {
                    title: '平均有效呼出数',
                    key: 'avgValidCallNum'
                },
                {
                    title: '总有效呼出数',
                    key: 'totalValidCallNum'
                },
                {
                    title: '首用客户',
                    key: 'firstCustomerNum'
                },
                {
                    title: '常用客户',
                    key: 'commonCustomerNum'
                },
                {
                    title: '付费客户',
                    key: 'payCustomerNum'
                },
                {
                    title: '录取客户',
                    key: 'enterCustomerNum'
                },
                {
                    title: '平均通话时长',
                    key: 'avgCallTime'
                },
                {
                    title: '录音',
                  key: 'index',
                  slot:'lx'
                },
                {
                    title: '通话小结',
                    key: 'index',
                    slot:'xj'
                },
                {
                    title: '客户转换率',
                    key: 'conversionRate'
                },
                {
                    title: '问题提交数',
                    key: 'feedbackNum'
                },
            ],
            list:[],
            total:0,
            loading:false

        };
    },
  methods: {
        showSummaryDialog(row) {
          this.$store.commit("summaryVisible", { row: row, showSummary: true });
      },
      showSoundDialog(row) {
        this.$store.commit("soundVisible", { row: row, showSound: true });
      },
        // 每月
        monthCompletion(){
            monthCompletion({
                ce:this.ce,
                ys:this.ys,
                os:this.os
            }).then(res=>{
                this.option=this.setBarOption(res.obj.completion,'month');
            }).catch(err => {
              this.option=this.setBarOption([],'month');
            })
        },
        // 当前
        currentCompletion(){
            currentCompletion({os:this.os}).then(res=>{
                this.optionCur=this.setBarOption(res.obj.completion,'current')
            }).catch(err => {
              this.optionCur =this.setBarOption([],'current')
            })
        },
        // 详情
        details(){
            this.loading=true
            details({
                os:this.os,
                ts:this.ts,
                of:this.of,
                lt:this.lt
            }).then(res=>{
                this.list=res.obj.statisticsDetail.records
                this.total=res.obj.statisticsDetail.total
                this.loading=false;
            }).catch(err => {
                this.list = [];
                this.total=0
                this.loading=false;
            })
        },
        changePage(e){
            this.of=e;
            this.details()
        },

        changeSize(e){
            this.of=1
            this.lt=e;
            this.details()
        },
        // type  month 月份倒序 current
        setBarOption(data,type){
            return  {
                color:['#8080ff','#ff728a'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow'
                    }
                },
                legend: {
                    data: ['未拨', '已拨']
                },
                xAxis: [
                    {
                    type: 'category',
                    axisTick: { show: false },
                    data:type=='month' ? data.map(item=>item.name).reverse() :data.map(item=>item.name)
                    }
                ],
                yAxis: [
                    {
                    type: 'value',
                    splitLine:{
                        show:false
                    }
                    }
                ],
                series: [
                    {
                        name: '未拨',
                        type: 'bar',
                        barGap: 0,
                        emphasis: {
                            focus: 'series'
                        },
                        data:type=='month' ?data.map(item=>item.dnot).reverse() :data.map(item=>item.dnot)
                    },
                    {
                        name: '已拨',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: type=='month' ? data.map(item=>item.already).reverse() : data.map(item=>item.already)
                    }
                ]
                }
        },
        changeOS() {
            this.of=1;
            this.details();
            this.monthCompletion()
            this.currentCompletion()
        }
    },
    created() {
        search().then(res=>{
            this.res=res.obj;
            this.ys=res.obj.years[0].id
            this.ce=res.obj.customerType[0].id
            this.ts = res.obj.timeSegments[1].id
            if (this.$store.state.userInfo.operatorsId) {
              this.os=this.$store.state.userInfo.operatorsId
            }
           
            this.monthCompletion()
            this.currentCompletion()
            this.details()
        })
    }
}
</script>
<style lang='scss' scoped>
    .customer {
        height: 100%;
        .top ,.bottom{
            height: 49%;
        }
        .lf,.rt,.bottom {
            border: 1px solid #b9b9b9;
            border-radius: 10px;
            padding: 20px;
        }
        .bottom {
            margin-top: 1%;
        }
        .lf {
            width: 70%;
            height: 100%;
            margin-right: 1%;
        }
        .rt {
            width: 29%;
            height: 100%;
        }
        .title {
            font-size: 16px;
            color: #525252;
        }
        ::v-deep .ivu-select {
            width: 90px;
            margin:0 10px;
            .ivu-select-selection {
                background: #747bad;
                height: 30px!important;
                line-height: 30px!important;
                span,i {
                    color:#fff;
                }
            }
        }
        .main {
            margin-top: 10px;
            width: 100%;
            height: 90%;
        }
        .table-main {
            height: calc(100% - 60px);
            cursor: pointer;
            overflow: auto;
        }
    }
    .bottom {
      ::v-deep .ivu-select {
            width: auto;
        }
    }
</style>