/*
 * @Author: jjx 1432652396@qq.com
 * @Date: 2022-10-31 09:49:22
 * @LastEditors: jjx 1432652396@qq.com
 * @LastEditTime: 2022-12-05 14:36:00
 * @FilePath: \winshawncrm\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router"
import {saveCallRecord,getUserInfo,outLogin} from "@/api"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    visitedList: [],
    callDetail: {},
    recordId:'',//通化记录id
    recordState: '',//通化状态
    isCount: false,
    summaryAll: {
      showSummary: false,
      row: {}
    },
    soundAll: {
      showSound: false,
      row: {}
    },
    showRecoScripts: {
      showReco: false,
      row: {}
    },
    updateCallDetail: {},
    beginCount: false,
    login: false,
    userInfo: {},
    visitedList:[]
  },
 
  mutations: {
    setStoreList(state, data) {
      
      state.visitedList = data
    },
    addList(state, data) {
      let list = state.visitedList 
     
      if (list.length==0) {
        list.push(data)
      }else {
        let i = list.findIndex(v=>v.url === data.url);
        if(i==-1){
          list.push(data)
        }
      }
      this.commit('setStoreList',list)
      this.commit('setLocalNav',list)
    },
    removeList(state, data) {
      let list = state.visitedList 
      let i = list.findIndex(v=>v.url === data.url);
      list.splice(i, 1)
      this.commit('setStoreList',list)
      this.commit('setLocalNav',list)
    },
    setLocalNav(state,list) {
      localStorage.setItem('visitedList',JSON.stringify(list))
    },
    changeCallData(state, data) {
      this.state.callDetail=data
    },
    setRecordId(state,data) {
      this.state.recordId=data
    },
    isCountTime(state,data) {
      this.state.isCount=data
    },
    summaryVisible(state,data) {
      this.state.summaryAll.showSummary=data.showSummary
      this.state.summaryAll.row=data.row
    },
    soundVisible(state,data) {
      this.state.soundAll.showSound=data.showSound
      this.state.soundAll.row=data.row
    },
    recoScriptsVisible(state,data) {
      this.state.showRecoScripts.showReco=data.showReco
      this.state.showRecoScripts.row=data.row
    },
    updateCallRecord(state,data) {
      this.state.updateCallDetail = data
      this.commit('beginCountTime',false)
    },
    beginCountTime(state,data) {
      this.state.beginCount=data
    },
    setLoginState(state,data) {
      state.login=data
    },
    setUserInfo(state,data) {
      state.userInfo=data
    }
  },
  actions: {
    saveCallRecord({commit}, payload) {
      return new Promise(resolve => {
        saveCallRecord({
          wid: this.state.callDetail.userid,
          cd: payload.jobId,
          ce:this.state.callDetail.ue
        }).then(res => {
          resolve(res)
          commit('setRecordId',res.obj.id)
        })
      })
    },
    getUserInfo({commit}) {
      return new Promise(resolve => {
        getUserInfo().then(res => {
          localStorage.setItem('userName',res.obj.crmUser.userName)
          resolve(res)
          commit('setUserInfo',res.obj.crmUser)
        }).catch(err => {
          console.log(err)
        })
      })
    },
    outLogin({commit}) {
      return new Promise(resolve => {
        outLogin().then(res => {
          localStorage.removeItem('tk')
          localStorage.removeItem('visitedList')
          commit('setStoreList',[])
          router.push({ path: '/login' })
          commit('setLoginState',false)  
        }).catch(err => {
          console.log(err)
        })
       })
    }
  },
  modules: {
  }
})
