<!--  -->
<template>
  <div class="user-administration">
    <div class="selection flex">
      <Select v-model="sri" placeholder="搜索角色">
        <Option :value="0" key="0">全部</Option>
        <Option v-for="item in roleList" :value="item.id" :key="item.id">{{
          item.roleName
        }}</Option>
      </Select>
      <Input v-model="suna" placeholder="搜索姓名" :maxlength="10" />
      <input type="button" value="搜索" class="btn search" @click="search" />
      <input type="button" value="重置" class="btn" @click="reset" />
    </div>
    <div class="btn-group flex">
      <div
        class="btn"
        @click="add"
      >
        <img src="../assets/images/add.png" />新增
      </div>
      <div class="btn" @click="edit">
        <img src="../assets/images/edit.png" />编辑
      </div>
      <div class="btn" @click="remove">
        <img src="../assets/images/del.png" />删除
      </div>
    </div>
    <div class="main-table">
      <Table
        :columns="columns"
        :data="list"
        @on-selection-change="selectionChange"
        border
      >
      <template slot-scope="{ row }" slot="operators">
            {{row.operators || "全部"}}
      </template>
    </Table>
    </div>
    <Page
      :total="total"
      :page-size="lt"
      :current="of"
      show-total
      show-elevator
      style="float: right"
      @on-change="changePage"
      @on-page-size-change="changeSize"
    />

    <!-- 新增编辑 -->
    <div class="layer" v-if="isShow">
      <div class="inner blockCenter">
        <p class="title">{{ type }}用户</p>
        <div>
          <label for="">登录账号</label
          ><input type="text" v-model="user.un" maxlength="20"  @keyup="user.un=user.un.replace(/\s*/g,'')"/>
        </div>
        <div>
          <label for="">登录密码</label
          ><input type="text" v-model="user.pw" maxlength="24"   @keyup="user.pw=user.pw.replace(/\s*/g,'')"/>
        </div>
        <div>
          <label for="">用户姓名</label
          ><input type="text" v-model="user.una" maxlength="10"   @keyup="user.una=user.una.replace(/\s*/g,'')"/>
        </div>
        <div>
          <label for="">所属机构</label>
          <Select v-model="user.oi" size="small" placeholder="所属机构">
            <Option
              v-for="item in res.operatorsList"
              :value="item.id"
              :key="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </div>
        <div>
          <label for="">用户角色</label>
          <Select v-model="user.ri" size="small" placeholder="用户角色">
            <Option
              v-for="item in res.roleList"
              :value="item.id"
              :key="item.id"
              >{{ item.roleName }}</Option
            >
          </Select>
        </div>
        <div>
          <label for="">手机号码</label
          ><input type="text" v-model="user.te" maxlength="11"   @keyup="user.te=user.te.replace(/\s*/g,'')"/>
        </div>
        <div v-if="user.ri == 54">
          <label for="">AK</label><input type="text" v-model="user.si"   @keyup="user.si=user.si.replace(/\s*/g,'')"/>
        </div>
        <div v-if="user.ri ==54">
          <label for="">SK</label><input type="text" v-model="user.ca"   @keyup="user.ca=user.ca.replace(/\s*/g,'')"/>
        </div>
        <div class="btn-group">
          <div class="btn" @click="save">确定</div>
          <div class="btn" @click="cancel">取消</div>
        </div>
      </div>
    </div>
    <!-- 删除 -->
    <div class="layer remove" v-if="showDel">
      <div class="inner blockCenter">
        <p>确认删除{{ name }}吗？</p>
        <div class="btn-group">
          <div class="btn" @click="removeItem">确定</div>
          <div class="btn" @click="cancel">取消</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 用户为超级管理员和管理员时，隐藏坐席ID和主叫号码；用户为客服时，显示坐席ID和主叫号码，且必填；
import { getSearchData, listPage, save, getSaveData, remove ,update} from "@/api";
export default {
  components: {},
  data() {
    return {
      roleList: [],
      suna: "",
      sri: 0,
      of: 1,
      lt: 12,
      list: [],
      total: 0,
      type: "",
      name: "",
      showDel: false,
      user: {
        un: "",
        pw: "",
        una: "",
        oi: 0,
        ri: "",
        te: "",
        si: "",
        ca: "",
      },
      columns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "登录账号",
          key: "account",
        },
        {
          title: "用户姓名",
          key: "userName",
        },
        {
          title: "所属机构",
          slot: "operators",
        },
        {
          title: "用户角色",
          key: "roleName",
        },
        {
          title: "手机号码",
          key: "tel",
        },
      ],
      isShow: false,
      selection: [],
      cui: "",
    };
  },
  methods: {
    listPage() {
      listPage({
        suna: this.suna,
        sri: this.sri,
        of: this.of,
        lt: this.lt,
      }).then((res) => {
        this.list = res.obj.crmUserList.records;
        this.total = res.obj.crmUserList.total;
      });
    },
    reset() {
      this.sri = 0;
      this.suna = "";
      this.of = 1;
      this.listPage();
    },
    search() {
      this.of = 1;
      this.listPage();
    },
    changePage(e) {
      this.of = e;
      this.listPage();
    },
    changeSize(e) {
      this.of = 1;
      this.lt = e;
      this.listPage();
    },
    selectionChange(selection) {
      this.selection = selection;
    },
    save() {
      let arr = Object.values(this.user);
      const { un, una, oi, ri, te, pw, cui } = this.user
      // 客服必填坐席id 主叫号码
      if (this.user.ri == 54) { 
        if (arr.some((k) => k === "")) {
            return this.$Message.info("请填写完整");
          }
        } else {
          if (un==''|| una==''|| te==''||  pw=='') {
            return this.$Message.info("请填写完整");
          }
        }
        if (!/^[1][0-9]{10}$/.test(this.user.te)) {
          return this.$Message.info("手机号不正确");
      }
      if(/[\u4E00-\u9FA5]/g.test(this.user.pw)){
          return this.$Message.info('密码不能有中文'); 
      }
      if (this.type === "新增") {
        save(this.user).then((res) => {
         this.resetUser()
        });
      } else {
        update(this.user).then((res) => {
          this.resetUser()
        });
      }
    },
    resetUser() {
      this.isShow = false;
      let keys = Object.keys(this.user);
      keys.map((key) => {
        this.user[key] = "";
      });
      this.user.ri = this.res.roleList[0].id;
      this.user.oi = 0;
      this.of = 1;
      this.listPage();
    },
    cancel() {
      let keys = Object.keys(this.user);
      keys.map((key) => {
        this.user[key] = "";
      });
      this.isShow = false;
      this.user.ri = this.res.roleList[0].id;
      this.user.oi = 0;
      this.showDel = false;
    },
    add() {
     this.user= {
        un: "",
        pw: "",
        una: "",
        oi: 0,
        ri: this.res.roleList[0].id,
        te: "",
        si: "",
        ca: "",
     },
      this.isShow = true;
      this.type = '新增';
    },
    edit() {
      if (this.selection.length == 0 || this.selection.length > 1) {
        return this.$Message.info("请勾选一行进行编辑");
      }
      this.type = "编辑";
      const {
        account: un,
        userName: una,
        operatorsId: oi,
        roleId: ri,
        tel: te,
        seatsId: si,
        caller: ca,
        id:cui
      } = this.selection[0];
      this.user = { un, una, oi, ri, te, si, ca, pw: "",cui };
      this.isShow = true;
    },
    remove() {
      if (this.selection.length == 0) {
        return this.$Message.info("请至少勾选一行删除");
      }
      this.cui = this.selection.map((item) => item.id).join(",");
      this.name = this.selection.map((item) => item.userName).join("、");
      this.showDel = true;
    },
    removeItem() {
      remove({ cuis: this.cui }).then((res) => {
        this.showDel = false;
        this.of = 1;
        this.listPage();
      });
    },
  },
  created() {
    getSearchData().then((res) => {
      this.roleList = res.obj.roleList;
    });
    getSaveData().then((res) => {
      res.obj.operatorsList.unshift({ id: 0, name: "全部" });
      this.user.ri = res.obj.roleList[0].id;
      this.res = res.obj;
    });
    this.listPage();
  },
};
</script>
<style lang="scss" scoped>
.user-administration {
  height: 100%;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: rgba($color: #fff, $alpha: 0.5);
  padding: 20px;
  .selection {
    height: 50px;
    .ivu-select {
      margin-right: 30px;
    }
  }
  .main-table {
    height: calc(100% - 140px);
  }
  .btn-group {
    .btn {
      background: #747bad;
      color: #fff;
      margin: 10px 20px 10px 0;
      display: flex;
      align-items: center;
      img {
        width: 12px;
        margin-right: 5px;
      }
      font-size: 14px;
    }
  }
  .layer {
    .inner {
      border-radius: 5px;
      background: #eff1fa;
      padding: 40px 100px;
      div {
        margin-top: 10px;
        label {
          color: #525252;
          font-size: 14px;
          width: 75px;
          display: inline-block;
        }
        .ivu-select,
        input {
          border: 1px solid #818080;
          text-align: center;
          background: transparent;
          width: 204px;
          height: 30px;
          line-height: 30px;
          border-radius: 3px;
        }
        .ivu-select-selection {
          background: none;
        }
      }
      .btn-group {
        display: flex;
        justify-content: center;
        .btn:last-child {
          background: #fff;
          color: #747bad;
          margin-right: 0;
          margin-left: 20px;
        }
      }
      .title {
        font-size: 16px;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }
  .remove {
    .inner {
      max-width: 500px;
      p {
        text-align: center;
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 1.4;
      }
    }
  }
}
</style>
