/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2021-03-09 17:54:35
 * @LastEditors: jjx 1432652396@qq.com
 * @LastEditTime: 2022-12-05 10:33:35
 */
import axios from 'axios'
import router from '../router'
import store from '../store'
import { Message } from "iview"

axios.defaults.timeout = 100000;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

axios.defaults.baseURL = process.env.VUE_APP_BASE_API  //workBench 工作台


// 请求拦截器
axios.interceptors.request.use(config=>{
    config.headers['tk']=localStorage.getItem('tk')||'';
    config.headers['na']=Math.random()*100
  return config; 
},err => {
  return Promise.reject(err)
})
// 响应拦截器
axios.interceptors.response.use(response => { 
    if(response.data.code!='00000') {
      if (response.data.code == 'A0230') {//token失效
        localStorage.removeItem('tk')
        localStorage.removeItem('visitedList')
        store.commit('setStoreList',[])
        router.push({ path: '/login' })
      } else {
        Message.error(response.data.msg)
      }
        return Promise.reject(response.data.msg)
    } else {
        store.commit('setLoginState',true)
        return Promise.resolve(response)
    }
    
}, err => {
    Message.error('网络错误')
    return Promise.reject('网络错误')
})
export default {
    fetchPost (url, params = {},config={}) {
        return new Promise((resolve, reject) => {
            axios.post(url,params,config).then(res => {
                resolve(res.data)
            }).catch(error => {
                reject(error)
            })
        })
    }
}