<!--
 * @Author: jjx 1432652396@qq.com
 * @Date: 2022-11-04 14:58:03
 * @LastEditors: jjx 1432652396@qq.com
 * @LastEditTime: 2022-11-30 13:26:07
 * @FilePath: \winshawncrm\src\components\call.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div class="layer">
    <div class="outer blockCenter">
      <div class="title flex-between">
        <span>录音</span>
        <Icon type="ios-close" size="30" @click="close" />
      </div>
      <div class="flex-between box">
        <div class="lf" v-if="$store.state.soundAll.row.id">
          <ul>
            <li
              v-for="(item, index) in record"
              :key="index"
              @click="changeAccount(index)"
              :class="{ active: accIndex == index }"
            >
              <img
                :src="
                  accIndex == index
                    ? require('../assets/images/white.png')
                    : require('../assets/images/teltx.png')
                "
              /><span>{{ item.account | sliceTr }}</span>
            </li>
          </ul>
        </div>
        <div class="mid">
          <img src="../assets/images/teltx.png" alt="" />
          <div>
            <span>{{account | sliceStr}}</span>
            <div>通话时间</div>
          </div>
          <ol v-if="soundAll.row.account">
              <li v-for="item,index in record" :key="index" @click="changeCur(index)" :class="{'active':cur==index}">{{item.createTime}}</li>
          </ol>
          <ol v-else>
              <li v-for="item,index in statisticsRecord" :key="index" @click="changeCur(index)" :class="{'active':cur==index}">{{item.createTime}}</li>
          </ol>
        </div>
        <div class="rt flex center">
          <img src="../assets/images/ly.png" alt="" />
          <audio :src="file" controls></audio>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMonoRecording, statisticsMonoRecording } from "@/api";
export default {
  data() {
    return {
      record: [],
      cur: 0,
      file: "",
      accIndex: 0,
      account: '',
      statisticsRecord: []
    };
  },
  computed: {
    soundAll() {
      return this.$store.state.soundAll;
    },
  },
  methods: {
    changeCur(index) {
      this.cur = index;
      if (this.soundAll.row.id) { 
        this.file = this.record[this.accIndex].sound_record[index].fileUrl;
      } else {
        this.file = this.record[index].fileUrl;
      }
    },
    close() {
      this.$store.commit("soundVisible", { row: {}, showSound: false });
    },
    changeAccount(index) {
      this.accIndex = index;
      this.cur = 0;
      this.account=this.record[index].account
      this.account = this.record[index].account;
      this.callContent = this.record[index].sound_record[0].callContent;
      this.statisticsRecord = this.record[index].sound_record;
    },
  },
  created() {
    if (this.soundAll.row.id) {
      statisticsMonoRecording({ sui: this.soundAll.row.id }).then((res) => {
        if (res.obj != null) {
          this.record = res.obj.soundRecord;
          this.file = this.record[0].sound_record[0].fileUrl;
          this.account = this.record[0].account
          this.statisticsRecord = this.record[0].sound_record;
        }
      });
    } else {
      this.account=this.soundAll.row.account
      getMonoRecording({ wid: this.soundAll.row.userid }).then((res) => {
        if (res.obj != null) {
          this.record = res.obj.record;
          this.file = this.record[0].fileUrl;
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.outer {
  background: #f4f5fc;
  padding: 3px;
  border-radius: 10px;
  width: 953px;
  height: 539px;
  cursor: pointer;
  .title {
    height: 39px;
    line-height: 39px;
    background: #747bad;
    color: #fff;
    padding: 0 10px;
    border-radius: 10px 10px 0 0;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .box {
    padding: 0 10px;
    & > div {
      border: 1px solid #747bad;
      height: 475px;
      border-radius: 10px;
      overflow: hidden;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 10px;
      border-bottom: 1px solid #747bad;
      img {
        width: 20px;
      }
      span {
        font-size: 16px;
      }
      &.active {
        background: #747bad;
        color: #fff;
      }
    }
    .lf {
      width: 23%;
      margin-right: 1%;
    }
    .mid {
      width: 23%;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      img {
        width: 20%;
        display: block;
        margin: 20px auto;
      }
      div {
        margin: 10px;
        font-size: 16px;
        text-align: center;
      }
    }
    .rt {
      justify-content: space-between;
      padding: 200px 0 30px;
      flex: 1;
      flex-direction: column;
      audio {
        width: 75%;
        background: none;
      }
    }
  }
}
</style>
