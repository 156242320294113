/*
 * @Author: jjx 1432652396@qq.com
 * @Date: 2022-10-31 09:49:22
 * @LastEditors: jjx 1432652396@qq.com
 * @LastEditTime: 2022-11-29 09:48:42
 * @FilePath: \winshawncrm\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import login from '../views/login.vue'
import submission from '../views/submission.vue'
import userAdministration from '../views/userAdministration.vue'
import addFeedBack from '../views/addFeedBack.vue'
import customer from '../views/customerServiceManagement.vue'
import firstuse from '../views/firstuse.vue'
import commonuse from '../views/commonuse.vue'
import enroll from '../views/enrollcustomers.vue'
import pay from '../views/paycustomers.vue'

Vue.use(VueRouter)


//解决路由重复时的报错问题，（报错但是不影响运行）
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect:'/submission',
    children:[
      {
        path: '/userAdministration',
        name: '用户管理',
        component: userAdministration
      },
      {
        path: '/submission',
        name: '问题提交',
        component: submission
      },
      {
        path: '/addFeedBack',
        name: '问题添加',
        component: addFeedBack
      },
      {
        path: '/customer',
        name: '客服管理',
        component: customer
      },
      {
        path: '/firstuse',
        name: '首用客户',
        component: firstuse
      },
      {
        path: '/commonuse',
        name: '常用客户',
        component: commonuse
      },
      {
        path: '/enroll',
        name: '录取客户',
        component: enroll
      },
      {
        path: '/pay',
        name: '付费客户',
        component: pay
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router