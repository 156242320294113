<template>
  <div class="home">
      <div class="top flex-between">
        <img src="../assets/images/logo.png" alt="winshawn">
        <ul class="flex">
          <li v-for="item,index in  navList" :key="index" @click="changeUrl(item)" :class="{'current':$route.path==item.url}">
            <span>{{item.name}}</span><Icon type="ios-close-circle" size="18" :color="$route.path==item.url?'#fff':'#747bad'"  @click.stop="remove(item)" v-if="navList.length>1"/>
          </li>
        </ul>
        <Poptip placement="bottom">
            <div class="flex tx">
              <img src="../assets/images/tx.png" width="40" />
              <span>{{$store.state.userInfo.userName}}</span>
            </div>
            <div slot="content" @click="outLogin">
                退出登录
            </div>
        </Poptip>
      </div>
      <div class="bottom">
        <navbar />
        <div class="right">
            <div class="table-main">
              <router-view></router-view>
            </div>
        </div>
      </div>  
  </div>
</template>

<script>


import navbar from "@/components/navbar.vue"
export default {
  name: 'Home',
  components:{
    navbar
  },
  data(){
    return {
      res: {},
    }
  },
  computed: {
    navList() {
      return this.$store.state.visitedList
    }
  },
  methods:{
    outLogin() {
      this.$store.dispatch('outLogin')
    },
    remove(item) {
      let list = this.$store.state.visitedList
      let i = list.findIndex(k => k.url == item.url)
      this.$store.commit('removeList', item)
      if (this.$route.path==item.url) {
        let url;
        if (i-1>-1) {
          url=this.$store.state.visitedList[i-1].url
        } else {
          url=this.$store.state.visitedList[i].url
        }
        this.$router.push({path:url});
      }
    },
    changeUrl(item) {
      this.$router.push({
        path:item.url
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .home {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  ::v-deep  {
    .ivu-input {
      height: 40px;
      border: none;
    }
  }
    .top {
      border-bottom: 1px solid #cbcdd3;
      align-items: flex-end;
      padding: 0 46px 0 16px;
      height: 57px;
      backdrop-filter:blur(20px);
      background: rgba($color: #fff, $alpha: 0.4);
      cursor: pointer;
      margin-bottom: 10px;
     img {
      margin-bottom: 15px;
     }
     .tx {
        margin-bottom: 10px;
        img  {
          margin: 0;
        }
      span {
        font-size: 14px;
        margin-left: 8px;
        vertical-align: top;
      }
     }
      ul {
        margin: 0 20px;
        flex: 1;
        align-items: flex-start;
        li {
          background: #fff;
          padding: 0 13px;
          margin-right:3px;
          height: 32px;
          line-height: 32px;
          border-radius: 10px 10px 0 0;
          span {
            font-size: 14px;
            margin-right: 5px;
            color:#4b4d54;
          }
          &.current {
            background: #747bad;
           span {
            color:#fff;
           }
          }
        }
      }
    }
    .bottom {
      height: calc(100vh - 68px);
      display: flex;
      .right {
        flex: 1;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        padding: 0 10px;
        .options {
          background: rgba($color: #fff, $alpha: 0.4);
          border: 1px solid #d4d5dd;
          border-radius: 14px;
          padding: 0 16px;
          height: 71px;
          margin-bottom: 10px;
          .optionItem {
            border-radius: 7px;
            overflow: hidden;
            p {
              height: 40px;
              line-height: 40px;
              background:#747bad;
              color:#fff;
              padding: 0 16px;
              font-size: 14px;
            }
          }
        }
        .table-main {
          height: 100%;
        }
      }
    }
</style>
