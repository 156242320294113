/*
 * @Author: jjx 1432652396@qq.com
 * @Date: 2022-11-01 10:03:13
 * @LastEditors: jjx 1432652396@qq.com
 * @LastEditTime: 2022-11-30 13:17:00
 * @FilePath: \winshawncrm\src\api\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * 
 */
import http from "./http"

export const login =(data)=>{
    return http.fetchPost("/api/nt/user/login",data)
}
export const outLogin =(data)=>{
    return http.fetchPost("/api/ut/user/outLogin",data)
}
export const firstCustomerSearch =(data)=>{
    return http.fetchPost("/api/ut/customer/firstCustomerSearch",data)
}
export const getSearchData =(data)=>{
    return http.fetchPost("/api/ut/crmUser/getSearchData",data)
}
export const listPage =(data)=>{
    return http.fetchPost("/api/ut/crmUser/listPage",data)
}
export const save =(data)=>{
    return http.fetchPost("/api/ut/crmUser/save",data)
}
export const getSaveData =(data)=>{
    return http.fetchPost("/api/ut/crmUser/getSaveData",data)
}
export const remove =(data)=>{
    return http.fetchPost("/api/ut/crmUser/remove",data)
}
export const getType =(data)=>{
    return http.fetchPost("/api/ut/feedback/getType",data)
}
export const feedListPage =(data)=>{
    return http.fetchPost("/api/ut/feedback/listPage",data)
}
export const feedbacksave =(data)=>{
    return http.fetchPost("/api/ut/feedback/save",data)
}
export const permission =(data)=>{
    return http.fetchPost("/api/ut/user/permission",data)
}
export const search =(data)=>{
    return http.fetchPost("/api/ut/statistics/search",data)
}
export const monthCompletion =(data)=>{
    return http.fetchPost("/api/ut/statistics/monthCompletion",data)
}
export const currentCompletion =(data)=>{
    return http.fetchPost("/api/ut/statistics/currentCompletion",data)
}
export const details =(data)=>{
    return http.fetchPost("/api/ut/statistics/details",data)
}
export const firstCustomer =(data)=>{
    return http.fetchPost("/api/ut/customer/firstCustomer",data)
}
export const commonCustomer =(data)=>{
    return http.fetchPost("/api/ut/customer/commonCustomer",data)
}
export const commonCustomerSearch =(data)=>{
    return http.fetchPost("/api/ut/customer/commonCustomerSearch",data)
}
export const enterCustomerSearch =(data)=>{
    return http.fetchPost("/api/ut/customer/enterCustomerSearch",data)
}
export const enterCustomer =(data)=>{
    return http.fetchPost("/api/ut/customer/enterCustomer",data)
}
export const saveEnterSchool =(data)=>{
    return http.fetchPost("/api/ut/customer/saveEnterSchool",data)
}
export const payCustomerSearch =(data)=>{
    return http.fetchPost("/api/ut/customer/payCustomerSearch",data)
}
export const payCustomer =(data)=>{
    return http.fetchPost("/api/ut/customer/payCustomer",data)
}
// 保存通话记录
export const saveCallRecord =(data)=>{
  return http.fetchPost("/api/ut/customer/saveCallRecord",data)
}
// 更新通话记录
export const updateCallRecord =(data)=>{
  return http.fetchPost("/api/ut/customer/updateCallRecord",data)
}
// 通话小结快捷关键词
export const getCallQuick =(data)=>{
  return http.fetchPost("/api/ut/customer/getCallQuick",data)
}
// 获取通话小结
export const remarks =(data)=>{
  return http.fetchPost("/api/ut/customer/remarks",data)
}
// 保存通话小结
export const updateRemarks =(data)=>{
  return http.fetchPost("/api/ut/customer/updateRemarks",data)
}
// 保存通话录音
export const getMonoRecording =(data)=>{
  return http.fetchPost("/api/ut/customer/getMonoRecording",data)
}
export const statisticsRemarks =(data)=>{
  return http.fetchPost("/api/ut/statistics/remarks",data)
}
export const getUserInfo =(data)=>{
  return http.fetchPost("/api/ut/crmUser/getUserInfo",data)
}
export const statisticsMonoRecording =(data)=>{
  return http.fetchPost("/api/ut/statistics/getMonoRecording",data)
}
export const update =(data)=>{
  return http.fetchPost("/api/ut/crmUser/update",data)
}

export const getSpeechcraft =(data)=>{
    return http.fetchPost("/api/ut/speechcraft/getSpeechcraft",data)
  }
